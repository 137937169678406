.login-modal {
  font-family: Arial, Helvetica, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 300px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-modal h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.login-modal form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-modal input {
  /* display: block; */
  width: 100% !important;
}

.login-modal form input[type="text"],
.login-modal form input[type="email"],
.login-modal form input[type="password"] {
  /* display: block; */
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

/* .modal form input[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: lightgray;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal form input[type="submit"]:hover {
  background-color: gray;
} */

/* CSS */
.login-submit-button {
  background-color: #fe5a5f;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 12px 150px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.login-submit-button:hover {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.login-submit-button:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.login-submit-button:disabled {
  background-color: white;
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}

.demo-submit-button {
  background-color: #ffffff;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 13px 132px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.demo-submit-button:hover {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.demo-submit-button:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.demo-submit-button:disabled {
  background-color: white;
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}

/* !!!!!   new css    !!!! */
.login-logout-inputs {
  display: flex;
  flex-direction: column;
  width: 420px;
  position: relative;
  /* right: 40px; */
  /* border: 1px solid red; */
}

#login-username {
  border: 1px solid #cdcdcd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 6px;
  border-bottom: none;
  padding: 20px;
  width: 380px;
}

#login-password {
  border: 1px solid #cdcdcd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  width: 380px;
}

.login-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  height: 535px;
  width: 500px;
}

.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  height: 535px;
  width: 500px;
}

.top-bar {
  display: flex;
  align-self: flex-start;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 65px;
  border-bottom: 1px solid lightgray;
}

.mark-div {
  /* border: solid 2px red; */
  left: 5%;
  margin-bottom: 3px;
  position: absolute;
  border-radius: 75%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mark-div:hover {
  background-color: #f5f5f5;
}

.welcome {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-indent: 30px;
  margin-top: 10px;
}

.log-in {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-weight: 625;
  margin-bottom: 5px;
}

.username-email {
  padding-top: 10px;
  height: 55px;
  width: 430px;
  position: absolute;
  top: 150px;
  left: 35px;
  border: 1px solid #bebebe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
}

.password {
  padding-top: 10px;
  height: 55px;
  width: 430px;
  position: absolute;
  top: 205px;
  left: 35px;
  border: 1px solid #bebebe;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
}

.login {
  color: white;
  font-size: 15px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  position: absolute;
  top: 310px;
  width: 430px;
  left: 35px;
  height: 50px;
  border-color: transparent;
  border-radius: 10px;
  background-size: 180% 180%;
  background-position: 50%;
  background-image: radial-gradient(
    circle at center center,
    rgb(255, 56, 92) 0%,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 40%,
    rgb(215, 4, 102) 57.5%,
    rgb(189, 30, 89) 75%,
    rgb(189, 30, 89) 100%
  );
  cursor: pointer;
}

.login:active {
  transform: scale(0.98);
}

.demob {
  color: white;
  font-size: 15px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  position: absolute;
  top: 310px;
  width: 430px;
  left: 35px;
  height: 50px;
  border-color: transparent;
  border-radius: 10px;
  background-size: 180% 180%;
  background-position: 50%;
  background-image: radial-gradient(
    circle at center center,
    rgb(255, 56, 92) 0%,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 40%,
    rgb(215, 4, 102) 57.5%,
    rgb(189, 30, 89) 75%,
    rgb(189, 30, 89) 100%
  );
  cursor: pointer;
}

.demob:active {
  transform: scale(0.98);
}

.or {
  /* border: solid 2px blue; */
  position: absolute;
  top: 395px;
  display: flex;
  flex-direction: row;
  width: 430px;
  left: 35px;
  justify-content: space-between;
  align-items: center;
}

.or-sides {
  /* border: solid 2px red; */
  height: 1px;
  width: 45%;
  background-color: #e8e8e8;
}

.demo-butt {
  position: absolute;
  top: 440px;
  left: 35px;
  height: 50px;
  width: 430px;
  border-radius: 10px;
  background-color: white;
  border: solid 2px black;
  font-size: 14px;
  font-weight: 400;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.demo-butt:active {
  transform: scale(0.98);
}

/* input {
  text-indent: 8px;
  font-size: 15px;
} */

/* body {
  display: flex;
  height: 100vh;
  align-items: center;
} */

.error-div {
  position: absolute;
  top: 275px;
  left: 40px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 13px;
  color: red;
}

#login-modal-labels-username {
  margin-left: -184px;
}

#login-modal-labels-password {
  margin-left: -184px;
  margin-top: 61.5px;
  z-index: 2;
}
