.map-search-wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  /* background-color: aqua; */
}

.google-maps-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 115%;
  width: 100%;
  /* background-color: blueviolet; */
}

.floating-map-toggle {
  position: fixed;
  bottom: 100px;
  left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  background-color: black;
  height: 60px;
  width: 160px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 50px -12px;
}

.fa-map {
  padding-left: 8px;
}

.marker-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  height: 20px;
  width: auto;
  border-radius: 15px;
  padding: 5px 8px;
  box-shadow: 0px 0px 3px #33333333;
  z-index: 1;
  font-weight: 800;
}
