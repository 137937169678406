.spot-card {
  /* border-style: dotted; */
  width: 300px;
  height: auto;
  color: black;
  font-family: Helvetica, sans-serif;
  text-decoration: none;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.spot-card-img {
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.hidden {
  visibility: hidden;
}

.bottom-card-info {
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-bottom: 0px;
  align-items: center;
}

.top-card-info {
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-top: 5px;
  font-weight: 600;
}

.lower-card-gap {
  overflow: hidden;
  /* height: 300px; */
}

/* unvisited link */
a:link {
  color: black;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: black;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: black;
  text-decoration: none;
}

/* selected link */
a:active {
  color: black;
  text-decoration: none;
}

.spot-title {
  font-weight: 300;
  color: grey;
}

.price {
  font-weight: 600;
}

.night {
  font-weight: 300;
}

.rating-section {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 300;
}

.submit-button-card {
  background-color: #ffffff;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 6px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.submit-button:hover {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.submit-button:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.submit-button:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}
