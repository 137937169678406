.map-search-wrapper-viewer-x {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%;
  /* background-color: aqua; */
}

.google-maps-box-viewer {
  height: 500px;
  width: 100%;
  /* background-color: blueviolet; */
  z-index: 3;
  padding-top: 20px;
}

.marker-label-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  font-size: 19px;
  color: white;
  border-radius: 50%;
  background-size: 200% 200%;
  background-position: 50%;
  background-image: radial-gradient(
    circle at center center,
    rgb(255, 56, 92) 0%,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 40%,
    rgb(215, 4, 102) 57.5%,
    rgb(189, 30, 89) 75%,
    rgb(189, 30, 89) 100%
  );
}
