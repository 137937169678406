.spot-name {
  font-weight: 500;
  padding-bottom: 5px;
  margin-top: -10px;
}

.spot-subheading {
  font-weight: 300;
  font-size: 1em;
}

.subheading-bold {
  font-weight: 600;
}

.star-subhead {
  padding-right: 5px;
}

.spot-page-parent {
  font-family: Helvetica, sans-serif;
  padding: 15px;

  display: flex;
  justify-content: center;
}

.spot-page-wrapper {
  /* justify-content: center; */
  max-width: 1100px;
  padding-bottom: 50px;
}

.top-info {
  padding: 20px;
}

.bottom-info {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.spot-details {
  width: 70%;
  padding-right: 40px;
  /* padding-top: 20px; */
}

.spot-description-heading {
  font-weight: 600;
  padding-top: 30px;
}

.perks-container {
  padding: 10px 0px;
}

.perk {
  padding: 15px;
  display: flex;
}
.perk-logo {
  font-size: 23px;
  padding-right: 15px;
}

.perk-text {
  display: flex;
  flex-direction: column;
}
.perk-heading {
  font-size: 18px;
  font-weight: 600;
}

.perk-description {
  /* font-size: 18px; */
  font-weight: 200;
  /* color: gray; */
}

.grey-divider {
  border-bottom: 2px solid lightgray;
  width: 100%;
  padding: 10px 0px 10px 0px;
}

.reserve-modal {
  position: sticky !important;
  top: 100px;
  width: 400px;
  height: fit-content;
  color: black;
  font-family: Helvetica, sans-serif;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
  border-radius: 10px;
  /* overflow: hidden; */
  padding: 20px;
}

.reserve-star {
  padding-right: 5px;
}

.reserve-modal-details {
  display: flex;
  justify-content: space-between;
}

.reserve-modal-button {
  display: flex;
  justify-content: center;
}

.booking-selection {
  margin-top: 10px;
  padding-top: 5px;
  display: flex;
  border: solid lightgray 1px;
  border-radius: 5px;
}

.date-input-label {
  font-size: 10px;
  color: gray;
  padding: 5px;
}

.check-in {
  border-right: solid lightgray 1px;
  width: 50%;
}

.check-out {
  width: 50%;
}

.date-input {
  width: fit-content;
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: 10px; */
  font-weight: bold;
  border: none;
}

.image-grid {
  --gap: 5px;
  --num-cols: 4;
  --row-height: 200px;

  box-sizing: border-box;
  padding: 0px 20px;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.image-grid > img {
  /* border-radius: 20px; */
  /* border-radius: 20px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.image-grid-row-2 {
  grid-row: span 2;
}

/* Anything udner 1024px */
@media screen and (max-width: 600px) {
  .image-grid {
    --num-cols: 2;
    --num-rows: 1;
    --row-height: 200px;
  }

  .img1,
  .img2,
  .img3,
  .img4 {
    visibility: hidden;
    position: fixed;
  }

  .image-grid-col-2 {
    border-radius: 15px;
  }
}

/* .spot-page-parent {
  width: 100%;
} */

.img-section {
  display: flex;
  width: 90%;
}
/* .preview-image {
  width: 50%;
} */

.other-img-gallery {
  width: 50%;
}

.other-img {
  height: 400px;
  width: 400px;
}

.gallery-top {
  display: flex;
}

.gallery-bottom {
  display: flex;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

/* CSS for reviews */
.reviews-section-parent {
  padding: 20px;
}

.reviews-header-text {
  margin-bottom: 15px;
}

.reviews-list {
  display: flex;
  flex-direction: column-reverse;
}

.img4 {
  border-bottom-right-radius: 20px;
}
.img2 {
  border-top-right-radius: 20px;
}

.submit-button-reserve {
  background-image: linear-gradient(to right, #e61e4d, #d70466);
  border: 0px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  /* display: inline-block; */
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 15px 50px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: 100%;
}

.submit-button-reserve:hover {
  /* box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px; */
  background-image: linear-gradient(to left, #e61e4d, #d70466);

  transition: box-shadow 0.2s;
}

.submit-button-reserve:active {
  background-color: #f7f7f7;
  /* border-color: #000000; */
  transform: scale(0.96);
}

.submit-button-reserve:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
  background-color: grey;
  background-image: none;
}

.owner-message {
  font-size: 13px;
  /* align-content: center; */
  padding-top: 17px;
  margin-top: 10px;
}

.owner-link-color {
  color: #ff385c;
  font-weight: 700;
}

.owner-button {
  margin-bottom: 15px;
}

.price-summary {
  padding-top: 15px;
}

.cost-summary-line {
  padding: 10px 0px;
}

.nightly-charge-summary {
  display: flex;
  justify-content: space-between;
}

.cleaning-fee {
  display: flex;
  justify-content: space-between;
}

.service-fee {
  display: flex;
  justify-content: space-between;
}

.price-lrg {
  font-size: 1.5em;
}

.summary-line {
  border-bottom: 1px solid lightgrey;
}

.summary-text {
  font-weight: 700;
}

.star-heading {
  font-size: large;
  padding-right: 10px;
}

.mapview-div {
}
