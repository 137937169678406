.hidden {
  visibility: hidden;
}

.navbar {
  position: sticky;
  top: 0px;
  z-index: 200;
  background-color: white;
  display: flex;
  font-family: Helvetica, sans-serif;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: rgb(231, 230, 230);
  padding: 15px 50px 10px 50px;
}

/* .profileButton:hover {
  box-shadow: 5px 5px 5px black;
} */
#nav-typelogo {
  /* width: 40px; */
  height: 40px;
  /* margin-left: 20px; */
}

#nav-logo {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.float-menu {
  position: absolute;
  background-color: white;
  /* border: solid black; */
  margin: 5px 0px 0px -80px;
  padding: 20px;
  border-radius: 10px;
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: center;
}

.profile-dropdown-email {
  padding-bottom: 10px;
}

.manage-spots {
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
}

.manage-trips {
  border-bottom: 1px solid;
  /* border-top: 1px solid; */
}

.manage-spots:hover {
  /* border-bottom: 1px solid; */
  background-color: #d3d3d3;
}

.manage-trips:hover {
  background-color: #d3d3d3;
  /* border-top: 1px solid; */
}

.profileButton {
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 75px;
  background-color: white;
}

i {
  font-size: 20px;
}

p {
  padding: 3px;
}
/* .nav-right {
  display: flex;
  align-items: center;
} */

.right-nav {
  display: flex;
  align-items: center;
  margin-left: -20px;
  gap: 15px;
}

.profile-modal {
  font-weight: 300;
}

.menu-links {
  /* color: brown; */
  /* cursor: pointer; */
  list-style-type: none;
  padding: 4px;
}

.logout-button {
  /* background-color: grey;
  border: 1px solid #222222;
  border-radius: 15px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 5px 0px 0px 15px;
  outline: none;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto; */
  border: none;
  font-size: 15px;
  padding-left: 3px;
  background-color: white;
  padding-top: 5px;
  width: 100%;
  display: flex;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #d3d3d3;
}

.fa-star {
  font-size: 13px;
}

.search-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  left: 50%;
  /* transform: translate(-50%, 0); */
  width: 352px;
  height: 40px;
  margin-bottom: -3px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 5px;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 25px;
  border: solid 1px #dddddd;
  cursor: pointer;
  padding-right: 6px;
  padding-left: 20px;
}

.search-bar-container:hover {
  box-shadow: lightgray 0px 2px 5px;
}

.search-bar-inner-wrapper {
  width: 100%;
}

.search-bar-inner-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-size: 200% 200%;
  background-position: 50%;
  background-image: radial-gradient(
    circle at center center,
    rgb(255, 56, 92) 0%,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 40%,
    rgb(215, 4, 102) 57.5%,
    rgb(189, 30, 89) 75%,
    rgb(189, 30, 89) 100%
  );
}

#search-bar-inner-search-icon {
  color: white;
  font-size: 12px;
}

input {
  border: none;
  /* width: 100%; */
}

input:focus {
  outline: none;
}

.fa-bars,
.fa-user-circle {
  color: #585858;
  margin-left: 9px;
  margin-right: 4px;
}

.fa-bars {
  font-size: 16px;
  margin-left: 15px;
}
.fa-user-circle {
  font-size: 28px;
  padding-right: 5px;
}

.create-new-spot-link {
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
  padding: 12px;
  right: 180px;
}

.create-new-spot-link:hover {
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
  padding: 12px;
  right: 180px;
  background-color: #f5f5f5;
  border: none;
}

.menu-icon {
  font-size: 13px;
  padding-right: 4px;
}

.fa-passport {
  /* font-size: 13px; */
  padding-right: 7px;
}
