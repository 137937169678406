.single-review {
  /* border-style: dotted; */
  color: black;
  font-family: Helvetica, sans-serif;
  text-decoration: none;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 0px;
}

.review-container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid pink; */
}

.review-heading {
  display: flex;
  align-items: center;
}

.review-header {
  /* border: 1px solid green; */
}
.review-body {
  /* border: 1px solid blue; */
}

.review-text {
  padding: 0px 10px;
}

.description {
  padding-top: 10px;
  font-weight: 300;
}

.date {
  color: grey;
  font-weight: 300;
}

.submit-button-red {
  background-color: red;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.fa-circle-user {
  font-size: 40px;
}
