/* .new-spot-form-div {

} */

.new-spot-form {
  max-width: 500px;
  margin: 2rem auto;
  border: 2px solid black;
  padding: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}

/* label {
  display: block;
  width: 100%;
  padding: 3px;
} */

.new-spot-form input,
textarea {
  display: block;
  width: 100%;
  padding: 5px;
}

.error {
  color: red;
}

/* .stack-left {
  margin: 0px 10px 0px 5px;
}
.stack-right {
  margin: 0px 20px 0px 5px;
} */

.form-stack {
  display: flex;
  justify-content: space-between;
}
/* CSS */
.submit-button {
  background-color: #ffffff;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0px 0px 0px;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.submit-button:hover {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.submit-button:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.submit-button:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}
