.trips-wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.trips-page-container {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  max-width: 1100px;
}

.trips-header {
  font-size: 32px;
  font-weight: 500;
}

.upcoming-trips-header {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 15px;
}

.upcoming-trips {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding-bottom: 30px;
  padding-top: 30px;
  gap: 20px;
}

.upcoming-trips-rev {
  /* border-bottom: 1px solid #ddd; */
  /* border-top: 1px solid #ddd; */
  display: flex;
  flex-direction: column-reverse;
  /* margin-top: 25px; */
  /* padding-bottom: 30px;
  padding-top: 30px; */
  gap: 20px;
}

.future-booking-card {
  display: flex;
  width: 100%;
  flex-direction: row;
  border: 1px solid #c8c8c8;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
}

.left-future-booking-card-info {
  flex: 1;
}

.right-future-booking-card-info {
  flex: 1;
}

.booking-card-upper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
}

.booking-card-name {
  font-size: 22px;
  margin-bottom: 10px;
}

.booking-card-location {
  color: #717171;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 20px;
}

.future-booking-img {
  object-fit: cover;
  object-position: center;
  /* overflow: hidden; */
  flex: 1;
  /* border-bottom-right-radius: 15px; */
  /* border-top-right-radius: 15px; */
  cursor: pointer;
}

.booking-card-lower {
  display: flex;
}

.booking-card-bottom-left {
  align-items: flex-start;
  border-right: 1px solid #eeeeef;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  min-width: 40%;
  flex-direction: column;
}

.booking-card-lower-left-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 20px;
  padding-top: 5px;
  width: 60%;
}

.booking-card-dates-and-nights {
  font-size: 17px;
}

.booking-card-length {
  color: #717171;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 5px;
}

.booking-card-bottom-right {
  display: flex;
  height: 130px;
  overflow: hidden;
  padding: 20px;
  width: 100%;
  text-overflow: ellipsis;
  /* border: 1px solid green; */
}

.booking-card-description {
  font-weight: 300;
  margin-top: 0;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* height: 100px; */
  /* border: 1px solid blue; */
}

.past-trips {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 30px;
}

.past-trips-header {
  font-size: 22px;
  font-weight: 400;
}

.past-trips-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
}

.past-booking-card {
  display: flex;
  width: 350px;
  height: 120px;
  overflow: hidden;
  /* border: 2px solid red; */
}

.past-booking-img {
  width: 30%;
  object-fit: cover;
  object-position: center;
  /* overflow: hidden; */
  flex: 1;
  border-radius: 15px;
  cursor: pointer;
}

.past-booking-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  padding-left: 15px;
}

.past-booking-top-info {
  display: flex;
  flex-direction: column;
}

.past-booking-top-info {
  display: flex;
  flex-direction: column;
}

.past-booking-grey-text {
  font-size: 13px;
  font-weight: 300;
  color: #a8a8a8;
}
