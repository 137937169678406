.search-page-wrapper {
  display: flex;
}

.search-results-summary {
  position: absolute;
  left: 30px;
}

.search-spots-container {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  padding-top: 25px;
  align-items: center;
}

.search-all-spots {
  padding-top: 30px;
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
}

.map-search-wrapper-search {
  width: 100%;
  /* height: 10%; */
  /* position: absolute; */
  /* border: 3px solid orange; */
}

.google-maps-box-search {
  position: fixed;
  /* left: 100px; */
  /* z-index: -1; */
  /* top: 0; */
  height: 100%;
  width: 100%;
  /* border: 3px solid green; */
}
