.manage-spots-header-text {
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
  /* margin-left: 15px; */
  font-family: Arial, Helvetica, sans-serif;
}

.spots-by-user-wrapper {
  display: flex;
  justify-content: center;
}

.manage-spots-header {
  font-weight: 300;
}
